import { createSlice } from '@reduxjs/toolkit';

export const userDataSlice = createSlice({
    name: 'userDataSliceName',
    initialState: {
        userDetails: {}
    },
    reducers: {
        updateUserDetails: (state, actions) => {
            state.userDetails = actions.payload;
        },
        removeUserDetails: (state, actions) => {
            state.userDetails = {};
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateUserDetails,
    removeUserDetails
} = userDataSlice.actions

export default userDataSlice.reducer