import Axios from "../utils/axiosInstance";

export const getRepProjects = (params, signal) => {
    return Axios.get("/api/Messages/GetAllRepProjects", { params: params, signal: signal });
};

export const getRepProjectDetails = (params, signal) => {
    return Axios.get("/api/Messages/GetRepProjectDetails", { params: params, signal: signal })
}

export const getTwilioChatToken = (params) => {
    return Axios.get("/api/Messages/GetAccessToken", { params })
}

export const getProjectChats = (projectId, isDeleted, signal) => {
    if (isDeleted) {
        return Axios.get(`/api/Messages/GetProjectChatIds?projectId=${projectId}&isDeleted=true`, { signal })
    }
    return Axios.get(`/api/Messages/GetProjectChatIds?projectId=${projectId}`, { signal })
}

export const postNewProjectChat = (payload) => {
    return Axios.post("/api/Messages/Post", payload)
}

export const getUserGroupChat = (params, signal) => {
    return Axios.get("api/Messages/GetGroupChat", { params: params, signal: signal })
}

export const addUserGroupChat = (payload) => {
    return Axios.post("/api/Messages/AddGroupChat", payload)
}

export const renameGroupName = (payload) => {
    return Axios.put("/api/Messages/RenameGroup", payload)
}

export const addUserToGroupChat = (payload) => {
    return Axios.post("/api/Messages/AddGroupMember", payload)
}

export const removeFromGroup = (params) => {
    return Axios.delete(`/api/Messages/LeaveGroupChat`, { params })
}

export const sendMentionEmail = (payload) => {
    return Axios.post("/api/Messages/SendEmailToMentioned", payload)
}

export const blockUserFromGroup = (payload) => {
    return Axios.put("/api/Messages/MarkUserAsBlockedFromGroup", payload)
}

export const deleteOneToOneChat = (params) => {
    return Axios.delete("/api/Messages/DeleteOneToOneChat", { params })
}

export const deleteGroupChat = (params) => {
    return Axios.delete("/api/Messages/DeleteGroupChat", { params })
}

export const restoreOneToOneChat = (payload) => {
    return Axios.put("/api/Messages/RestoreOneToOneChat", payload)
}

export const restoreGroupChat = (payload) => {
    return Axios.put("/api/Messages/RestoreGroupChat", payload)
}

export const DownloadBlobByEntity = (guid, entityType, entityId) => {
    return Axios.get(`/api/Blob/GetByEntity?blobNameGUID=${guid}&entityName=${entityType}&entityId=${entityId}`, { responseType: "arraybuffer" })
}

export const getProjectAllChats = (params) => {
    return Axios.get("/api/Messages/GetRepAllProjectDetails", { params })
}


export const getAllProjects = (params) => {
    return Axios.get("/api/AdminMessages/GetAllAdminRepProjects", { params })
}

export const getAdminProjectDetails = (params, signal) => {
    return Axios.get("/api/AdminMessages/GetAdminProjectDetails", { params: params, signal: signal })
}

export const getAllProjectChat = (params) => {
    return Axios.get("/api/AdminMessages/GetAdminProjectChatIds", { params })
}

export const getAllGroupChat = (params) => {
    return Axios.get("/api/AdminMessages/GetAdminGroupChat", { params })
}

export const getAdminReadOnlyChats = (params) => {
    return Axios.get("/api/AdminMessages/GetAdminReadOnlyChats", { params })
}

export const RenameGroupNameByAdmin = (payload) => {
    return Axios.post("/api/AdminMessages/RenameGroupName", payload)
}

export const removeMemberFromReadOnlyGroupChat = (params) => {
    return Axios.delete("/api/AdminMessages/RemoveMemberFromGroupChat", { params })
}

export const removeMemberFromGroupByAdmin = (params) => {
    return Axios.delete("/api/AdminMessages/RemoveUserFromGroupChat", { params })
}

export const deleteReadOnlyOneToOneChatByAdmin = (params) => {
    return Axios.delete("/api/AdminMessages/DeleteOneToOneChatByAdmin", { params })
}

export const deleteProjectChatByAdmin = (params) => {
    return Axios.delete("/api/AdminMessages/DeleteProjectChats", { params })
}

export const deleteReadOnlyGroupChatByAdmin = (params) => {
    return Axios.delete("/api/AdminMessages/DeleteGroupChatByAdmin", { params })
}

export const createGeneralGroup = (payload) => {
    return Axios.post("/api/Messages/GenerateGeneralGroup", payload)
}