import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfiguration } from "../constants/msalConfig";

const pca = new PublicClientApplication(msalConfiguration);
const account = pca.getAllAccounts()[0];

const accessTokenRequest = {
  scopes: [process.env.REACT_APP_MSAL_SCOPE],
  account: account,
};

const logoutRequest = {
    account: account,
    mainWindowRedirectUri: process.env.REACT_APP_FE_URL,
  };

let apiVersion = process.env.REACT_APP_API_BASE_URL;
const instance = axios.create({
    baseURL: apiVersion,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' }
})
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instance.interceptors.request.use(
    async (config) => {
        let token = await pca.acquireTokenSilent(accessTokenRequest)
        // Acquire token silent success
        config.headers.pageURL = window.location.href;
        if (token.accessToken) {
            config.headers.Authorization = `Bearer ${token.accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

let isAlreadyFetchingAccessToken = false;
let requests = [];

const onAccessTokenFetched = (access_token) => {
    requests = requests.filter((callback) => callback(access_token));
};

const addRequest = (callback) => {
    requests.push(callback);
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    const originalRequest = error?.config;
    if (status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        
        // refreshAccessToken({ email })
        //   .then((res) => {
        //     let {
        //       token,
        //       refreshToken,
        //       loginId,
        //       contactId,
        //       clientId,
        //       instrId,
        //       fullName,
        //       webRole,
        //       agencyInstrId
        //     } = res.data;
        //     const loginReducerData = {
        //       email: email,
        //       token: token,
        //       refreshToken: refreshToken,
        //       auth: true,
        //       loginId: loginId,
        //       contactId: contactId,
        //       clientId: clientId,
        //       instrId: instrId,
        //       fullName: fullName,
        //       webRole: webRole,
        //       agencyInstrId: agencyInstrId
        //     };
        //     const action = updateUserData(loginReducerData);
        //     store.dispatch(action);
        //     isAlreadyFetchingAccessToken = false;
        //     onAccessTokenFetched(token);
        //   })
        //   .catch(() => {
        //     window.location.href = "/logout";
        //   })
        //   .catch((res) => {
        //     window.location.href = "/logout";
        //   });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addRequest((access_token) => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(instance.request(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

export default instance;
