import React from "react";
import AppRouter from "./AppRouter";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfiguration } from "./constants/msalConfig";
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const App = () => {

  const pca = new PublicClientApplication(msalConfiguration);
  
  return (
    <div className="App">
      <MsalProvider instance={pca}>
        <AppRouter />
      </MsalProvider>
    </div>
  );
}

export default App;
